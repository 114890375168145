import React from 'react';
import style from './CourseBoxComponentV2.module.css';

const CourseBoxComponentV2 = (props) => {
    const { firstTitle, secondTitle, firstSubtitle, secondSubtitle, thirdSubtitle, img, clickBtn } = props;

    const clickEvent = () => {
        clickBtn();
    }

    return (
        <div className={style.course_box}>
            <div className={style.leftbox}>
                <div className={style.title}>
                    <div>
                        {firstTitle}
                    </div>
                    <div>
                        {secondTitle}
                    </div>
                </div>
                <div className={style.subtitle}>
                    <p>
                        {firstSubtitle}
                    </p>
                    <p>
                        {secondSubtitle}
                    </p>
                    <p className={style.price}>
                        {thirdSubtitle}
                    </p>
                </div>

                <div className={style.img_box}>
                    <div className={style.btn_box}>
                        <div className={style.btn} onClick={clickEvent}>我要报名</div>
                    </div>
                </div>
            </div>
            <img src={img} alt='' />
        </div>
    );
};

export default CourseBoxComponentV2;