import { React } from 'react';
import style from './ResourceBoxComponent.module.css';
import icon from '../../assets/resources/icon.svg';

const ResourceBoxComponent = (props) => {
  const { title, btnClick, img } = props;

  return (
    <div
      className={style.hightlight_box}
    >
      <div className={style.single_box}>
        <img src={icon} alt='' className={style.icon} />
        <div className={style.imgbox} style={{
          backgroundImage: `url(${img})`,
        }}></div>
        <div className={style.title_box}>
          <div className={style.title}>
            {title}
          </div>
        </div>
        <div className={style.btn} onClick={()=>{
          btnClick();
        }}>立即领取</div>
      </div>
    </div>
  );
};

export default ResourceBoxComponent;