import { React, useState, useEffect } from 'react';
import style from './HightlightBoxComponent.module.css';

const HightlightBoxComponent = (props) => {
  const { title, subtitle, img, hoverimg, content } = props;
  const [isHovered, setIsHovered] = useState(false);
  // const width = window.innerWidth;

  const useWidth = () => {
      const [width, setWidth] = useState(0)
      const handleResize = () => {
          setWidth(window.innerWidth)
      }
      useEffect(() => {
          handleResize()
          window.addEventListener('resize', handleResize)
          return () => window.removeEventListener('resize', handleResize)
          // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
      return width
  }
  const width = useWidth();

  useEffect(() => {
    // 组件挂载完成时的逻辑
    console.log(`Component mounted with props:`);
    if (width != 0 && width <= 820) {
      setIsHovered(true);
    }

    // 清理函数
    return () => {
      console.log(`Component will unmount`);
    };
  }, [width]);


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (width != 0 && width <= 820) {
      return;
    }
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={style.hightlight_box}
      style={{
        background: isHovered ? '#FFA516' : 'rgba(0, 0, 0, 0.05)',
        color: isHovered ? '#fff' : '#000',
        justifyContent: isHovered ? 'flex-start' : 'center'
      }}>
      <div className={style.single_box}>
        <div className={style.title_box}>
          <div className={style.title}>
            {title}
          </div>
          <div className={style.subtitle}>
            {subtitle}
          </div>
        </div>
        <div className={style.imgbox}>
          <img alt="" src={isHovered ? (width != 0 && width > 820 ? hoverimg : img) : img} />
        </div>
      </div>
      <div className={style.content_box} style={{
        display: isHovered ? 'block' : 'none'
      }}>
        {content.split('enter').map(
          (item) => {
            return <div>{
              item.includes('bold') ?
                item.split('bold').map((littleItem, i) => {
                  return i % 2 === 0 ?
                    <p className={style.inline} style={{
                      opacity: isHovered ? '0.7' : '0'
                    }}>{littleItem}</p> :
                    <p className={[style.inline, style.bold].join(' ')} style={{
                      opacity: isHovered ? '0.9' : '0'
                    }}>
                      {littleItem}
                    </p>
                })
                : <p className={style.inline} style={{
                  opacity: isHovered ? '0.7' : '0'
                }}>{item}</p>}
            </div>
          })}
      </div>

    </div>
  );
};

export default HightlightBoxComponent;