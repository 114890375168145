import { React } from 'react';
import style from './HistoryComponent.module.css';

const HistoryComponent = (props) => {
  const { title, subtitle, img } = props;

  return (
    <div className={style.hightlight_box} >
      <div className={style.single_box}>
        <div className={style.teacher}>
          <img alt='history' src={img} />
        </div>
        <div className={style.title_box}>
          <div className={style.title}>
            {title}
          </div>
          <div className={style.subtitle}>
            {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryComponent;