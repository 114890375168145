import React from 'react';
import style from './CourseBoxComponent.module.css';

const CourseBoxComponent = (props) => {
    const { firstTitle, secondTitle, firstSubtitle, secondSubtitle, thirdSubtitle, img, clickBtn, isCFA } = props;

    const clickEvent = () => {
        clickBtn();
    }

    return (
        <div className={isCFA ? style.cfacourse_box : style.course_box}>
            <div className={style.title}>
                <div>
                    {firstTitle}
                </div>
                <div>
                    {secondTitle}
                </div>
            </div>
            <div className={style.subtitle}>
                <p>
                    {firstSubtitle}
                </p>
                <p>
                    {secondSubtitle}
                </p>
                <p className={style.price}>
                    {thirdSubtitle}
                </p>
            </div>

            <div className={style.img_box}>
                <img src={img} alt='' />
                <div className={style.btn_box}>
                    <div className={style.btn} onClick={clickEvent}>我要报名</div>
                    <div className={style.btn_border}></div>
                </div>
            </div>
        </div>
    );
};

export default CourseBoxComponent;