import { React, useState, useEffect } from 'react';
import style from './AdvantageBoxComponent.module.css';

const AdvantageBoxComponent = (props) => {
  const { title, subtitle, img, hoverimg, hover } = props;
  const [isHovered, setIsHovered] = useState(false);
  // const width = window.innerWidth;
  
  const useWidth = () => {
      const [width, setWidth] = useState(0)
      const handleResize = () => {
          console.log('resize')
          setWidth(window.innerWidth)
      }
      useEffect(() => {
          handleResize()
          window.addEventListener('resize', handleResize)
          return () => window.removeEventListener('resize', handleResize)
      }, [])
      return width
  }
  const width = useWidth();

  useEffect(() => {
    // 组件挂载完成时的逻辑
    console.log(`Component mounted with props:`);
    if (width != 0 && width <= 820) {
      setIsHovered(true);
    }

    // 清理函数
    return () => {
      console.log(`Component will unmount`);
    };
  }, [width]);

  const handleMouseEnter = (e) => {
    // console.log(e,e.match(/\d+/)[0]);
    // const index = e.split("advantages")[1].substr(0, 1);
    const index = e.match(/\d+/)[0];
    hover(index);
    setIsHovered(true);
  };

  const handleMouseLeave = (e) => {
    if (width != 0 && width <= 820) {
      return;
    }
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={() => { handleMouseEnter(img) }}
      onMouseLeave={handleMouseLeave}
      className={style.box}
      style={{
        background: isHovered ? width > 820 ? '#FFA516' : 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.05)',
        color: isHovered ? width > 820 ? '#fff' : '#000' : '#000'
      }}>
      <div className={style.single_box}>
        <div className={style.imgbox}>
          <img alt='' src={isHovered ? width >= 820 ? hoverimg : img : img} />
        </div>
        <div className={style.title_box}>
          <div className={style.title}>
            {title}
          </div>
          <div className={style.subtitle} style={{
            display: isHovered ? 'block' : 'none'
          }}>
            {subtitle}
          </div>
        </div>

      </div>
    </div>
  );
};

export default AdvantageBoxComponent;