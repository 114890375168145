import { React, useState } from 'react';
import './Login.css';

//----------------- Images -----------------------------------//
import LOGO from "../../assets/login/logo.svg";
import LOGO2 from "../../assets/login/logo.png";

//----------------- Icons -----------------------------------//
import ICON_FORK from "../../assets/login/icon_fork.svg";
import ICON_MINE_NAME from "../../assets/login/icon_mine_name.svg";
import ICON_MINE_PHONE from "../../assets/login/icon_mine_phone.svg";
import ICON_MINE_STUDY from "../../assets/login/icon_mine_study.svg";
import ICON_MINE_STUDY_SELECTED from "../../assets/login/icon_mine_study_selected.svg";
import ICON_MINE_VIP from "../../assets/login/icon_mine_vip.svg";
import ICON_HOME_PEOPLE from "../../assets/login/icon_home_people.svg";
import ICON_HOOK from "../../assets/login/icon_hook.png";
import ICON_LIST_CLOSE from "../../assets/login/icon_list_close.svg";
import ICON_LIST_OPEN from "../../assets/login/icon_list_open.svg";
import ICON__CLOSE_WHITE from "../../assets/login/icon_close_white.svg";
import ICON_LOGIN_CLOSE from "../../assets/login/icon_login_close.svg";
import ICON_LOGIN_MIMA from "../../assets/login/icon_login_mima.svg";
import ICON_LOGIN_PHONE from "../../assets/login/icon_login_phone.svg";
import ICON_LOGIN_SELECTED from "../../assets/login/icon_login_selected.svg";
import ICON_LOGIN_UNSELECTED from "../../assets/login/icon_login_unselected.svg";
import ICON_LOGIN_YINCANG from "../../assets/login/icon_login_yincang.svg";
import ICON_LOGIN_ZHANSHI from "../../assets/login/icon_login_zhanshi.svg";
import ICON_MINE_BIRTHDAY from "../../assets/login/icon_mine_birthday.svg";
import ICON_MINE_CLASS from "../../assets/login/icon_mine_class.svg";
import ICON_MINE_CLASS_SELECTED from "../../assets/login/icon_mine_class_selected.svg";
import ICON_MINE_EMAIL from "../../assets/login/icon_mine_email.svg";
import ICON_MINE_MINE from "../../assets/login/icon_mine_mine.svg";
import ICON_MINE_MINE_SELECTED from "../../assets/login/icon_mine_mine_selected.svg";
import ICON_QUESTIONBANK_CALCULATOR from "../../assets/login/icon_questionbank_calculator.svg";
import ICON_QUESTIONBANK_CFA from "../../assets/login/icon_questionbank_cfa.svg";
import ICON_QUESTIONBANK_CFA_SELECTED from "../../assets/login/icon_questionbank_cfa_selected.svg";
import ICON_QUESTIONBANK_COLLECT from "../../assets/login/icon_questionbank_collect.svg";
import ICON_QUESTIONBANK_COLLECT_SELECTED from "../../assets/login/icon_questionbank_collect_selected.png";
import ICON_QUESTIONBANK_LOCATION from "../../assets/login/icon_questionbank_location.svg";
import ICON_QUESTIONBANK_MARK from "../../assets/login/icon_questionbank_mark.svg";
import ICON_QUESTIONBANK_MARK_SELECTED from "../../assets/login/icon_questionbank_mark_selected.png";
import ICON_QUESTIONBANK_RECORD from "../../assets/login/icon_questionbank_record.svg";
import ICON_QUESTIONBANK_RECORD_SELECTED from "../../assets/login/icon_questionbank_record_selected.svg";
import ICON_QUESTIONBANK_SCHEDULE from "../../assets/login/icon_questionbank_schedule.svg";
import ICON_QUESTIONBANK_TIMING from "../../assets/login/icon_questionbank_timing.svg";
import ICON_LEFT from "../../assets/login/icon_left.svg";
import ICON_RIGHT from "../../assets/login/icon_right.svg";

import { useEffect } from "react";
import { message } from 'antd';
import { storage } from "../../utils/Storage";

// 许可协议地址
const licenseUrl = "https://cpa.lanchenedu.com/h5x/userAgreement.html";
// 隐私政策地址
const privacyUrl = "https://cpa.lanchenedu.com/h5x/privacy.html";
const DEFAULT_CACHE_TIME = 60 * 60 * 24 * 7;

const Images = {
    LOGO,
    LOGO2,
};
const Icons = {
    ICON__CLOSE_WHITE,
    ICON_LEFT,
    ICON_RIGHT,
    ICON_FORK,
    ICON_MINE_NAME,
    ICON_MINE_PHONE,
    ICON_MINE_STUDY,
    ICON_MINE_STUDY_SELECTED,
    ICON_MINE_VIP,
    ICON_HOME_PEOPLE,
    ICON_HOOK,
    ICON_LIST_CLOSE,
    ICON_LIST_OPEN,
    ICON_LOGIN_CLOSE,
    ICON_LOGIN_MIMA,
    ICON_LOGIN_PHONE,
    ICON_LOGIN_SELECTED,
    ICON_LOGIN_UNSELECTED,
    ICON_LOGIN_YINCANG,
    ICON_LOGIN_ZHANSHI,
    ICON_MINE_BIRTHDAY,
    ICON_MINE_CLASS,
    ICON_MINE_CLASS_SELECTED,
    ICON_MINE_EMAIL,
    ICON_MINE_MINE,
    ICON_MINE_MINE_SELECTED,
    ICON_QUESTIONBANK_CALCULATOR,
    ICON_QUESTIONBANK_CFA,
    ICON_QUESTIONBANK_CFA_SELECTED,
    ICON_QUESTIONBANK_COLLECT,
    ICON_QUESTIONBANK_COLLECT_SELECTED,
    ICON_QUESTIONBANK_LOCATION,
    ICON_QUESTIONBANK_MARK,
    ICON_QUESTIONBANK_MARK_SELECTED,
    ICON_QUESTIONBANK_RECORD,
    ICON_QUESTIONBANK_RECORD_SELECTED,
    ICON_QUESTIONBANK_SCHEDULE,
    ICON_QUESTIONBANK_TIMING,
};

function isPhone(a) {
    const reg = /^1[3456789]\d{9}$/;
    return reg.test(a);
}

const Login = (props) => {
    const { handleClose } = props;

    const [phone, setPhone] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [password, setPassword] = useState('');
    const [loginType, setLoginType] = useState(1);
    const [sendCodeBtnDisabled, setSendCodeBtnDisabled] = useState(true);
    const [loginBtnDisabled, setLoginBtnDisabled] = useState(true);
    const [isSendCode, setIsSendCode] = useState(false);
    const [sendCodeCountDown, setSendCodeCountDown] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [isReset, setIsReset] = useState(false);

    useEffect(() => {
        if (isPhone(phone) && sendCodeCountDown === 0) {
            setSendCodeBtnDisabled(false);
        } else {
            setSendCodeBtnDisabled(true);
        }

        if (loginType === 1) {
            if (isPhone(phone) && captcha && isSendCode) {
                setLoginBtnDisabled(false);
            } else {
                setLoginBtnDisabled(true);
            }
        } else {
            if (isPhone(phone) && password) {
                setLoginBtnDisabled(false);
            } else {
                setLoginBtnDisabled(true);
            }
        }

        if (sendCodeCountDown > 0) {
            const timer = setTimeout(() => {
                setSendCodeCountDown(sendCodeCountDown - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [phone, captcha, sendCodeCountDown, loginType, password]);


    const handleSendCode = () => {

        const data = { phone: phone }; // POST请求的参数
        fetch('/api/app/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(result => {
                console.log('success')
                setIsSendCode(true)
                setSendCodeCountDown(60)
                message.success("发送验证码成功");
            })
            .catch(error => {
                console.log('error')
                console.error(error)
                message.warning("发送验证码失败");
            });
    };

    const handleLogin = () => {
        const params = {
            phone: phone,
            type: loginType,
            captcha: captcha,
            password: "",
        };
        if (loginType == 2) {
            params.password = password;
        }
        fetch('/api/auth/pcLogin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {

                console.log(result)
                if (result.code !== 200) {
                    message.warning(result.msg);
                    return;
                }
                message.success("登录成功");
                // 重新加载页面
                setTimeout(async () => {
                    // 登录成功，初始化用户信息
                    const ex = result.data.expires_in;
                    // 设置 ACCESS_TOKEN
                    storage.set("ACCESS-TOKEN", result.data.token, ex);
                    fetch('/api/web/user/get', {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': result.data.token
                        }
                    })
                        .then(response => response.json())
                        .then(response => {
                            console.log('success')
                            storage.set("CURRENT-USER", response.data, DEFAULT_CACHE_TIME);
                            handleClose();
                            window.location.reload();
                        })
                        .catch(error => {
                            console.log('error')
                            console.error(error)
                        });
                }, 2000);
            })
            .catch(error => {
                message.warning("登录失败，请重新登录");
            });
    };

    const resetPassword = () => {
        setIsReset(true);
    };

    return (
        <div className="modal-card">
            <div className="modal-card-head">
                <img src={Images.LOGO2} className="logo" />
                <p className="modal-card-title">登录揽辰</p>
                {/* <div onClick={() => {
                    handleClose()
                }} style={{ cursor: 'pointer' }}>
                    <img src={Icons.ICON_LOGIN_CLOSE} className="close" />
                </div> */}
            </div>
            <section className="modal-card-body">
                <div className="tabs">
                    <ul>
                        <li className={loginType == 1 ? 'is-active' : ''}>
                            <a
                                href="javaScript:;"
                                className="is-flex is-flex-direction-row is-align-items-center"
                                onClick={() => {
                                    setLoginType(1)
                                }}
                            >
                                <span className="icon">
                                    <img src={Icons.ICON_LOGIN_PHONE} />
                                </span>
                                <span>手机号登录</span>
                            </a>
                        </li>
                        <li className={loginType == 2 ? 'is-active' : ''}>
                            <a
                                href="javaScript:;"
                                className="is-flex is-flex-direction-row is-align-items-center"
                                onClick={() => {
                                    setLoginType(2)
                                }}
                            >
                                <span className="icon">
                                    <img src={Icons.ICON_LOGIN_MIMA} />
                                </span>
                                <span>密码登录</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="form">
                    <div className="field">
                        <div className="control">
                            <input
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="input"
                                type="number"
                                placeholder="请输入手机号"
                            />
                        </div>
                    </div>
                    {loginType === 1 && (
                        <div className="field is-grouped">
                            <p className="control is-expanded">
                                <input
                                    value={captcha}
                                    onChange={(e) => setCaptcha(e.target.value)}
                                    className="input"
                                    type="number"
                                    placeholder="请输入验证码"
                                />
                            </p>
                            <p className="control sendcodep">
                                <button
                                    disabled={sendCodeBtnDisabled}
                                    onClick={handleSendCode}
                                    className="button is-black sendcode"
                                    style={{
                                        cursor: sendCodeBtnDisabled ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    {`发送验证码${sendCodeCountDown > 0 ? `(${sendCodeCountDown}s)` : ''}`}
                                </button>
                            </p>
                        </div>
                    )}
                    {loginType === 2 && (
                        <div className="field password_field">
                            <div className="control">
                                <input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="input"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="请输入密码"
                                />
                            </div>
                            <div className="icon eye is-small">
                                <img
                                    onClick={() => setShowPassword(!showPassword)}
                                    src={showPassword ? Icons.ICON_LOGIN_ZHANSHI : Icons.ICON_LOGIN_YINCANG}
                                />
                            </div>
                        </div>
                    )}
                    <div className="field stay-login">
                        <div className="control">
                            <input id="stay-checked" type="checkbox" />
                            <label htmlFor="stay-checked">保持登录</label>
                        </div>
                        {/* <a onClick={resetPassword} href="javaScript:;" className="yellow">忘记密码?</a> */}
                    </div>
                    <button disabled={loginBtnDisabled}
                        onClick={handleLogin}
                        className="sub-btn button is-primary">
                        登 录
                    </button>
                    <div className="login-text">
                        未注册将自动创建账号。注册登陆即代表您已阅读并同意揽辰会计
                        <a href={licenseUrl} target="_blank">《许可协议》</a>和
                        <a href={privacyUrl} target="_blank">《隐私政策》</a>
                    </div>
                </div>
            </section >
        </div >
    );
};

export default Login;