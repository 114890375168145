import { React } from 'react';
import style from './AboutusBoxComponent.module.css';

const AboutusBoxComponent = (props) => {
  const { title, subtitle1, subtitle2, img } = props;

  return (
    <div

      className={style.hightlight_box}
    >
      <div className={style.single_box}>
        <div className={style.title_box}>
          <div className={style.title}>
            {title}
          </div>
          <div className={style.subtitle}>
            {subtitle1}
          </div>
          <div className={style.subtitle}>
            {subtitle2}
          </div>
        </div>
        <div className={style.imgbox} style={{
          backgroundImage: `url(${img})`,
        }}></div>
      </div>
    </div>
  );
};

export default AboutusBoxComponent;